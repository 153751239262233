import React from 'react'
import { Formik, Field, Form, ErrorMessage } from 'formik'
import * as Yup from 'yup'
import { css, jsx } from '@emotion/core'
import styled from '@emotion/styled'
import theme from '../../../config/theme'
import { rhythm } from '../../lib/typography'
import { bpMaxSM } from '../../lib/breakpoints'
import Message from '../ConfirmMessage/Message'
import { PleaseConfirmIllustration } from '../ConfirmMessage/Illustrations'
import addToMailchimp from 'gatsby-plugin-mailchimp'

const SubscribeSchema = Yup.object().shape({
  email_address: Yup.string()
    .email('Invalid email address')
    .required('Required'),
  first_name: Yup.string(),
})

const PostSubmissionMessage = ({ response }) => {
  return (
    <div>
      <Message
        illustration={PleaseConfirmIllustration}
        title={`Welcome to the club.`}
        body={`Thanks for subscribing!`}
      />
    </div>
  )
}

const H2 = styled.h2`
  margin-bottom: ${rhythm(1)};
`

class SignUp extends React.Component {
  state = {
    submitted: false,
  }

  async handleSubmit(values) {
    this.setState({ submitted: true })
    try {
      const result = await addToMailchimp(values.email_address, {
        FNAME: values.first_name,
        LNAME: values.last_name,
      })

      this.setState({
        submitted: true,
        response: result.result,
        msg: result.msg,
        errorMessage: null,
      })
    } catch (error) {
      this.setState({
        submitted: false,
        errorMessage: 'Something went wrong!',
      })
    }
  }

  render() {
    const { submitted, response, errorMessage, msg } = this.state
    const successful = response && response === 'success'

    return (
      <div
        css={css`
          margin: 0 auto;
          background: white;
          padding: 10px;
          border-radius: 5px;
        `}
      >
        {!successful && (
          <h3
            css={css`
              font-size: 22px;
            `}
          >
            What I'm reading, talking about, and working on, weekly in your
            inbox:
          </h3>
        )}

        <Formik
          initialValues={{
            email_address: '',
            first_name: '',
            last_name: '',
          }}
          validationSchema={SubscribeSchema}
          onSubmit={values => this.handleSubmit(values)}
          render={({ errors, touched, isSubmitting }) => (
            <>
              {!successful && (
                <div
                  css={css`
                    display: flex;
                    align-items: flex-end;
                    .field-error {
                      display: block;
                      position: absolute;
                      color: ${theme.colors.red};
                      font-size: 80%;
                    }
                    input,
                    label {
                      width: 100%;
                    }
                    ${bpMaxSM} {
                      flex-direction: column;
                      align-items: flex-start;
                      width: auto;
                      label,
                      input {
                        margin: 5px 0 0 0 !important;
                        width: 100%;
                        display: flex;
                        flex-direction: column;
                      }
                      button {
                        margin: 20px 0 0 0;
                      }
                    }
                  `}
                >
                  <Form
                    css={css`
                      width: 100%;
                    `}
                  >
                    <div
                      css={css`
                        display: flex;
                        flex-direction: column;
                      `}
                    >
                      <div
                        css={css`
                          display: flex;
                          justify-content: space-between;
                          align-items: flex-end;
                        `}
                      >
                        <label htmlFor="first_name">
                          First Name
                          <ErrorMessage
                            name="first_name"
                            component="span"
                            className="field-error"
                          />
                          <Field
                            aria-label="your first name"
                            aria-required="false"
                            name="first_name"
                            placeholder="Michael"
                            type="text"
                            css={css`
                              height: 30px;
                              border: none;
                              background: #eee;
                              border-radius: 5px;
                              :focus {
                                background: white;
                              }
                              :placeholder {
                                color: black;
                              }
                            `}
                          />
                        </label>
                      </div>
                      <StyledField>
                        <label htmlFor="last_name">
                          Last Name
                          <ErrorMessage
                            name="last_name"
                            component="span"
                            className="field-error"
                          />
                          <Field
                            aria-label="your last name"
                            aria-required="false"
                            name="last_name"
                            placeholder="Angelo"
                            type="text"
                            css={css`
                              height: 30px;
                              border: none;
                              background: #eee;
                              border-radius: 3px;
                              :focus {
                                background: white;
                              }
                            `}
                          />
                        </label>
                      </StyledField>
                    </div>
                    <label htmlFor="email">
                      Email
                      <StyledField>
                        <ErrorMessage
                          name="email_address"
                          component="span"
                          className="field-error"
                        />
                      </StyledField>
                      <div
                        css={css`
                          @media only screen and (min-width: 550px) {
                            width: 100%;
                        `}
                      >
                        <Field
                          aria-label="your email address"
                          aria-required="true"
                          name="email_address"
                          placeholder="letmeout@sistine.com"
                          type="email"
                          css={css`
                            height: 30px;
                            border: none;
                            background: #eee;
                            border-radius: 3px;
                            :focus {
                              background: white;
                            }
                          `}
                        />
                      </div>
                    </label>

                    <button
                      data-element="submit"
                      type="submit"
                      disabled={isSubmitting}
                      css={css`
                        height: 30px;
                        margin-top: 10px;
                        background: #eee;
                        border: none;
                        border-radius: 3px;
                      `}
                    >
                      {!isSubmitting && 'Submit'}
                      {isSubmitting && 'Submitting...'}
                    </button>
                  </Form>
                </div>
              )}
              {submitted && successful && (
                <PostSubmissionMessage response={msg} />
              )}
              {errorMessage && <div>{errorMessage}</div>}
            </>
          )}
        />
      </div>
    )
  }
}

const StyledField = styled.div`
  display: flex
  justify-content: space-between
  align-items: flex-end
  
`

export default SignUp
